/*Style skeleton*/

.skeleton {
    background: rgb(255, 255, 255);
    margin: 10px 0;
    border-radius: 4px;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 70%);
    }
    100% {
        background-color: hsl(0, 0%, 81%);
    }
}

.skeleton.text {
    width: 100%;
    height: 12px;
}

.title {
    height: 15px;
    margin-bottom: 15px;
    opacity: .7;
    animation: skeleton-loading .3s linear infinite alternate;
}

.title.ads,
.title.visible_cate,
.title.subs,
.title.home_cate {
    width: 10%;
}

.title.article,
.title.adMob,
.title.users {
    width: 30%;
}

.skeleton.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.skeleton.thumbnail {
    width: 100px;
    height: 100px;
}