.add__resources {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add__resources input {
    width: 25rem;
}

.add__icon {
    font-size: 1.7rem;
    margin-top: 1.6rem;
    cursor: pointer;
}