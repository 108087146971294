.subscriber__button {
    display: flex;
    width: 17%;
    margin-bottom: 7px;
}

.uil-edit-alt,
.uil-trash-alt {
    font-size: 25px;
    cursor: pointer;
    color: rgb(13, 100, 172);
}

tbody+thead {
    display: none;
}